import './Foot.css';

export default function Foot() {
  return (
    <footer className="Foot">
      <p>
      Built by KovaKreative using React with NES.css
      </p>
      {/* <div className="social">

      </div> */}
    </footer>
  );
}